import React from "react"
import PropTypes from 'prop-types'
import ReactDisqusComments from 'react-disqus-comments'
import * as S from "./styled"

const Comments = ({ url, title }) => {
    return (
        <S.CommentsWrapper>
            <S.CommentsTitle>Comentários</S.CommentsTitle>
            <ReactDisqusComments
                shortname="danilogila"
                identifier={url}
                title={title}
                url="http://www.example.com/example-thread"
            />
        </S.CommentsWrapper>
      );
}

Comments.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default Comments