import React from 'react'
import { graphql } from 'gatsby'

import Comments from '../components/Comments'
import Layout from "../components/Layout/"
import Seo from "../components/seo"

import * as S from '../components/Post/styled'

export const query = graphql`
query Post($slug: String!) {
    markdownRemark(fields: {slug: { eq: $slug}}) {
      frontmatter {
        title
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        image
      }
      fields {
        slug
      }
      html
      timeToRead
    }
  }
  
`

const BlogPost = ({ data }) => {
    const post = data.markdownRemark
    return (
      <Layout page="post">
        <Seo title={post.frontmatter.title} description={post.frontmatter.title} image/>

        <S.PostHeader>
          <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
          <S.PostDate>{post.timeToRead} minutos de leitura</S.PostDate>
          <S.PostDescription></S.PostDescription>
        </S.PostHeader>
        <S.MainContent>
          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </S.MainContent>
        <Comments url={post.fields.slug} title={post.frontmatter.title} />
      </Layout>
    )
}

export default BlogPost