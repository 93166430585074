import styled from "styled-components"

export const CommentsWrapper = styled.section`
  iframe[src*="ads-iframe"] {
    display: none;
  }
  #disqus_thread {
    a {
      color: #1fa1f2 !important;
    }
  }
`

export const CommentsTitle = styled.h2`
  color: #333;
  font-size: 2.1rem;
  font-family: "Staatliches";
  padding-bottom: 2rem;
  padding-top: 2rem;
  border-top: 2px solid #333;
`